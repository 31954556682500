import React from "react";
import google_play from "../resources/images/landing-page/InstallationGuide/android_install.png";
import apple_store from "../resources/images/landing-page/InstallationGuide/apple_install.png";
import { ANDROID_INSTALL, IOS_INSTALL } from "../words";

const AppInstallBtn = () => {
  return (
    <div className="app-install-buttons">
      <a className="app_install" href={IOS_INSTALL}>
        <img src={apple_store} alt="app-store-install-button" />
      </a>
      <a className="app_install" href={ANDROID_INSTALL}>
        <img src={google_play} alt="google-play-install-button" />
      </a>
    </div>
  );
};

export default AppInstallBtn;
